<template>
  <div class='card'>
    <div class='container'>
      <b-modal ref='addVehicleThreeSixtyImagesModal' hide-footer>
        <addVehicleThreeSixtyImages @closeAddVehicleThreeSixtyImages='toggleAddVehicleThreeSixtyImages()' @addVehicleThreeSixtyImagesSuccess='addVehicleThreeSixtyImagesSuccess()'></addVehicleThreeSixtyImages>
      </b-modal>
      <b-modal ref='editVehicleThreeSixtyImagesModal' hide-footer>
        <editVehicleThreeSixtyImages
          :editingVehicleThreeSixtyImages='currentVehicleThreeSixtyImages'
          @closeEditVehicleThreeSixtyImages='toggleEditVehicleThreeSixtyImages()'
          @editVehicleThreeSixtyImagesSuccess='editVehicleThreeSixtyImagesSuccess()'
        ></editVehicleThreeSixtyImages>
      </b-modal>
      <div class='row'>
        <h2>VehicleThreeSixtyImages</h2>
      </div>
      <div class='row'>
        <button @click='toggleAddVehicleThreeSixtyImages()' type='button' class='btn btn-success'>Add</button>
      </div>
      <div class='row'>
        <br>
        <datatable :dataItems='items' @rowClicked='editItem' :dataColumns='columns' :searching='true' :sorting='true' @editRow='editItem' :order='[1, "dec"]' class='text-center' striped bordered />
      </div>
    </div>
  </div>
</template>
<script>
import addVehicleThreeSixtyImages from '../../components/addVehicleThreeSixtyImages';
import editVehicleThreeSixtyImages from '../../components/editVehicleThreeSixtyImages';
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      columns: [
              {
                label: 'Id',
                field: 'Id',
                sort: 'asc',
              },
              {
                label: 'ModelId',
                field: 'ModelId',
                sort: 'asc',
              },
              {
                label: 'ColourName',
                field: 'ColourName',
                sort: 'asc',
              },
              {
                label: 'Image',
                field: 'Image',
                sort: 'asc',
              },
              {
                label: 'ImageMeta',
                field: 'ImageMeta',
                sort: 'asc',
              },
              {
                label: 'LastUpdated',
                field: 'LastUpdated',
                sort: 'asc',
              },
      ],
      items: [],
      addVehicleThreeSixtyImagesOpen: false,
      editVehicleThreeSixtyImagesOpen: false,
      currentVehicleThreeSixtyImages: {}
    };
  },
  components: {
    addVehicleThreeSixtyImages,
    editVehicleThreeSixtyImages,
    Datatable,
  },
  created: function() {
    this.getVehicleThreeSixtyImages();
    this.addVehicleThreeSixtyImagesOpen = false;
    this.editVehicleThreeSixtyImagesOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return '';
    },
    isNull: function(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
 getVehicleThreeSixtyImages() {
      var payload = {
        success: response => {
          this.items = response.data;
        },
        error: error => { miniToastr['error'](error, 'Error', 1000, null)}
      }
      this.$store.dispatch('getVehicleThreeSixtyImages', payload);
    },
    editItem(VehicleThreeSixtyImages) {
      this.toggleEditVehicleThreeSixtyImages();
      this.currentVehicleThreeSixtyImages = VehicleThreeSixtyImages;
    },
    toggleAddVehicleThreeSixtyImages() {
      if(this.addVehicleThreeSixtyImagesOpen)
      {
        this.$refs.addVehicleThreeSixtyImagesModal.hide()
      }
      else{
        this.$refs.addVehicleThreeSixtyImagesModal.show()
      }
      this.addVehicleThreeSixtyImagesOpen = !this.addVehicleThreeSixtyImagesOpen;
    },
    addVehicleThreeSixtyImagesSuccess() {
      this.toggleAddVehicleThreeSixtyImages();
      miniToastr['success']('VehicleThreeSixtyImages Added', 'Success', 1000, null);
      this.getVehicleThreeSixtyImages();
    },
    toggleEditVehicleThreeSixtyImages() {
      if(this.editVehicleThreeSixtyImagesOpen)
      {
        this.$refs.editVehicleThreeSixtyImagesModal.hide()
      }
      else{
        this.$refs.editVehicleThreeSixtyImagesModal.show()
      }
      this.editVehicleThreeSixtyImagesOpen = !this.editVehicleThreeSixtyImagesOpen;
    },
    editVehicleThreeSixtyImagesSuccess() {
      this.toggleEditVehicleThreeSixtyImages();
      miniToastr['success']('VehicleThreeSixtyImages Edited', 'Success', 1000, null);
      this.getVehicleThreeSixtyImages();
    }
  }
};
</script>
<style scoped>
.VehicleThreeSixtyImagesThumbnail {
  height: 50px;
}
</style>
